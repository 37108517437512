.site-footer {
  &__connect {
    .menu__item--lvl-1 {
      @include breakpoint($mobile-only) {
        width: 100%;
      }
    }
    .menu__item--lvl-2:last-child {
      @include breakpoint($mobile-only) {
        float: $rdirection;
      }
    }
  }
}
