///
/// @file  _aveda-base-mobile.scss
///
/// \brief Pull all parts of the theme scss together into one file
///
@import 'style';

html,
body {
  font-family: $primary-font;
}